@import './../../assets/styles/variables.scss';

.login-bg {
  background: rgb(2, 0, 36);
  background: linear-gradient(43deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 93, 1) 21%, rgba(169, 203, 222, 1) 100%);
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .login-card {
    width: 440px;
    padding: 1rem;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

    .login-header {
      padding: 1rem;
      text-align: center;
      .logo {
        width: 4rem;
        height: 4rem;
        margin: 1rem;
      }
    }
  }

  @media only screen and (max-width: 450px) {
    .login-card {
      width: 100%;
      height: 100%;
      padding: 0.5rem;
      font-size: 0.5rem;
      border-radius: 0;
      box-shadow: none;

      .login-header {
        font-size: 0.8rem;
        padding: 0.5rem;
        .logo {
          width: 3rem;
          height: 3rem;
          margin: 0.5rem;
        }
      }
    }
  }
}

.signup-card {
  width: 90%;
  padding: 1rem 2rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  margin: 1rem;

  .signup-contents {
    margin: 1rem;
  }
}
